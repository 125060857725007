var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "pan-back-btn",
          attrs: { icon: "arrow-left" },
          on: { click: _vm.back },
        },
        [_vm._v("返回")]
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v("Oops!"),
            ]),
            _c("h2", [_vm._v("你没有权限去该页面")]),
            _c("h6", [_vm._v("如有不满请联系你领导")]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [_vm._v("或者你可以去:")]),
              _c(
                "li",
                { staticClass: "link-type" },
                [
                  _c("router-link", { attrs: { to: "/dashboard" } }, [
                    _vm._v("回首页"),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              attrs: {
                src: _vm.errGif,
                width: "313",
                height: "428",
                alt: "Girl has dropped her ice cream.",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }